import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-data-loading',
  templateUrl: '../data-loading-new/data-loading.component.html',
  styleUrls: ['./data-loading.component.scss'],
  host: {
    class: 'flex flex-col items-center justify-center my-6',
  },
})
export class DataLoadingComponent {
  @Input() loading = true;

  @Input() heading = 'common.loading';
}
