import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { mspotLinkType } from './help-link-type';

@Component({
  selector: 'mspot-help-link',
  templateUrl: './help-link.component.html',
  styles: [],
  host: {
    class: 'flex items-center sm:block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpLinkComponent {
  @Input() link?: mspotLinkType;
  @Input() showTextMobile = false;
}
